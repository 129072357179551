export default {
  name: "WarehousesContentLayout",
  data() {
    return {
      //VARIABLES
      heightTable: 0,
      bHeightTable: 0,
      texts: "",
      aWarehouses: [],
      restantHeight: 0,
      screenHeight: 0,
      iCurrentPage: 1,
      iItemsPerPage: 12,
      iNumPages: 0,
      iTotalItems: 0,
      iExample: 0,
    };
  },
  beforeMount() {
    this.$store.commit("setMenuSidebar", { active: false });
    this.texts = FILE.warehouseTexts[this.selectLanguage];
    this.getWarehouses();
  },
  updated() {
    // this.resizeDiv();
  },
  created() {
    window.addEventListener("load", this.contentTableHeight);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize", this.resizeDiv);
    this.resizeDiv();
  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.resizeDiv);
  },
  methods: {
    contentTableHeight() {
      this.heightTable = document.getElementsByClassName("tableContent");
      if (this.heightTable[0] !== undefined) {
        this.bHeightTable =
          this.heightTable[0].clientHeight > 1270 || window.innerHeight < 960;
      }
    },
    handleResize() {
      this.contentTableHeight();
      this.screenHeight = window.innerHeight - 400;
    },
    resizeDiv: function() {
      this.$nextTick((e) => {
        this.screenHeight = window.innerHeight - 400;
        this.iExample = this.iExample + 1;
        var contentHeight = document.getElementsByClassName("text-address");
        let tam = [];
        for (let i = 0; i < contentHeight.length; i++) {
          const element = contentHeight[i];
          tam.push(element.clientHeight);
        }
        let max = Math.max(...tam);
        this.restantHeight = max;
      });
    },
    addWarehouses: function() {
      this.$store.commit("setDialogAddWarehouse", {
        active: true,
      });
    },
    detailWarehouse: function(id) {
      this.$router.push(`/admin/warehouse-detail/${id}`);
    },
    redirectWarehouses: function() {
      this.$router.push("/admin/warehouses");
    },
    getWarehouses: function() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          },
        }
      )
        .then((response) => {
          this.aWarehouses = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.resizeDiv();

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
    refresh: function() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function() {
      this.getWarehouses();
    },
    iCurrentPage: function() {
      this.getWarehouses();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.warehouseTexts[this.selectLanguage];
      }
      this.getWarehouses();
    },
  },
};
